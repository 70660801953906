import { Link, NavLink } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { DATE_FORMAT_FRONT } from "../../../utils/constants";

const account_name = {
  headerName: "Account",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  field: "account_name",
  minWidth: 400,
  maxWidth: 400,
  filter: true,
  comparator: () => {}, //disable default sorting
};

const account_internal_id = {
  headerName: "Internal ID",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  field: "internal_id",
  maxWidth: 120,
  filter: true,
  comparator: () => {}, //disable default sorting
};

const owedLifeTime = {
  headerName: "Lifetime B.",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  field: "life_time_balance",
  maxWidth: 140,
  filter: true,
  comparator: () => {}, //disable default sorting
};
const owedlt30 = {
  sortable: true,
  minWidth: 100,
  maxWidth: 100,
  headerName: "B. 30",
  field: "balance_30",
  valueGetter: (params) => params.data.balance_30 || 0,
  comparator: () => {}, //disable default sorting
};

const owedlt60 = {
  sortable: true,
  minWidth: 100,
  maxWidth: 100,
  headerName: "B. 60",
  field: "balance_60",
  valueGetter: (params) => params.data.balance_60 || 0,
  comparator: () => {}, //disable default sorting
};

const owedlt90 = {
  sortable: true,
  minWidth: 100,
  maxWidth: 100,
  headerName: "B. 90",
  field: "balance_90",
  valueGetter: (params) => params.data.balance_90 || 0,
  comparator: () => {}, //disable default sorting
};

const owedlt120 = {
  sortable: true,
  minWidth: 100,
  maxWidth: 100,
  headerName: "B. 120",
  field: "balance_120",
  valueGetter: (params) => params.data.balance_120 || 0,
  comparator: () => {}, //disable default sorting
};

const owedlt180 = {
  sortable: true,
  minWidth: 100,
  maxWidth: 100,
  headerName: "B. 150",
  field: "balance_150",
  valueGetter: (params) => params.data.balance_150 || 0,
  comparator: () => {}, //disable default sorting
};

const managers = {
  sortable: true,
  minWidth: 300,
  headerName: "Collectors",
  field: "collector",
  filter: true,
  valueGetter: (params) =>
    params.data?.managers?.map((manager) => manager.name).join(","),
  cellRenderer: (params) => {
    const managers = params.data?.managers
      ?.map((manager) => manager.name)
      .join(",");
    return (
      <Box>
        {managers && <Typography>{managers}</Typography>}
        {!managers && <Typography color={"error"}>No manager!</Typography>}
      </Box>
    );
  },
  wrapText: true,
  autoHeight: true,
  comparator: () => {}, //disable default sorting
};

const hasCollector = {
  sortable: true,
  minWidth: 180,
  headerName: "Has Collectors",
  field: "hasCollector",
  filter: true,
  valueGetter: (params) =>
    params.data?.managers?.length > 0 ? "true" : "false",
  wrapText: true,
  autoHeight: true,
  hide: true,
};

const account_general_note = {
  sortable: true,
  minWidth: 180,
  headerName: "account_general_note",
  field: "account_general_note",
  filter: true,
  valueGetter: (params) => params.data?.account_general_note,
  wrapText: true,
  autoHeight: true,
  hide: true,
};

const task_date = {
  sortable: true,
  minWidth: 180,
  headerName: "Date",
  field: "date",
  filter: true,
  valueGetter: (params) =>
    params.data.date ? dayjs(params.data.date).format("YYYY-MM-DD") : "-",
  cellRenderer: (params) => (
    <Typography>
      {params.data.date
        ? dayjs(params.data.date).format(DATE_FORMAT_FRONT)
        : "-"}
    </Typography>
  ),
  wrapText: true,
  autoHeight: true,
  sort: "asc",
  comparator: () => {}, //disable default sorting
};

const CollectionsCols = [
  task_date,
  account_internal_id,
  account_name,
  managers,
  hasCollector,
  //last_call,
  owedLifeTime,
  owedlt30,
  owedlt60,
  owedlt90,
  owedlt120,
  owedlt180,
];

export default CollectionsCols;
