import {
  emailSGE,
  listBatchStatements,
  listGroupStatementBatches,
} from "../../distflowAPI/invoicesApi";
import DataTable from "../../components/DataTable";
import {
  formatAgGridFilters,
  formatAgGridSorting,
} from "../../distflowAPI/common";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { downloadFile } from "../../distflowAPI/downloadFile";
import { getNoPages } from "../../utils";

function getCols(extraParams) {
  return [
    {
      headerName: "Batch no",
      field: "id",
      cellRenderer: (params) => (
        <Typography onClick={() => extraParams.setOpen(params.data?.id)}>
          batch #{params.data?.id}
        </Typography>
      ),
    },
    {
      headerName: "from",
      field: "start_date",
    },
    {
      headerName: "till",
      field: "end_date",
    },
    {
      headerName: "Group",
      field: "group.name",
    },
    {
      headerName: "batch date",
      valueGetter: (params) => params.data?.created,
    },
    {
      headerName: "Status",
      valueGetter: (params) =>
        `processed ${params.data?.processed_reports} of ${params.data?.total_reports}`,
    },
  ];
}

function Batches() {
  const { id } = useParams();
  const [batches, setBatches] = useState([]);
  const [orderBy, setOrderBy] = useState([]);
  const [filterBy, setFilterBy] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [dataLoading, setDataLoading] = useState(false);
  const [noPages, setNoPages] = useState(1);
  const [open, setOpen] = useState(null);
  const batchCols = getCols({ setOpen });
  useEffect(() => {
    listGroupStatementBatches(id).then((res) => setBatches(res.data.results));
  }, []);

  return (
    <Box>
      <Statements open={open} setOpen={setOpen} batches={batches} />
      <Box>
        <DataTable
          columnDefs={batchCols}
          rowData={batches}
          rowHeight={53}
          headerHeight={60}
          loading={dataLoading}
          onSortChanged={(event) =>
            setOrderBy(
              formatAgGridSorting(event.columnApi.columnModel.gridColumns)
            )
          }
          onFilterChanged={(event) =>
            setFilterBy(formatAgGridFilters(event.api.getFilterModel()) || [])
          }
          page={page}
          pageSize={pageSize}
          setPageSize={(pageSize) => setPageSize(pageSize)}
          noPages={noPages}
          setPage={(page) => setPage(page)}
        />
      </Box>
    </Box>
  );
}

export default Batches;

const batchStatsCols = [
  {
    headerName: "Account",
    field: "account.name",
    flex: 6,
  },
  {
    headerName: "Emailed",
    field: "emailed",
    flex: 2,
  },
  {
    headerName: "Status",
    field: "comment",
    flex: 6,
  },
  {
    headerName: "Actions",
    cellRenderer: (params) => (
      <Button
        onClick={() => {
          if (params.data.file) {
            downloadFile(
              params.data.file,
              `${params.data.account.name} ${params.data.start_date} ${params.data.end_date}.pdf`,
              true
            );
          }
        }}
      >
        {params.data.file ? "Download" : "Not Ready"}
      </Button>
    ),
    flex: 2,
  },
];

function Statements({ open, setOpen, batches }) {
  const [statements, setStatements] = useState([]);
  const [page, setPage] = useState(1);
  const [noPages, setNoPages] = useState(1);
  const [accountFilter, setAccountFilter] = useState("");

  function fetchData() {
    if (open) {
      listBatchStatements(open, true, page, {
        account__name: accountFilter,
      }).then((res) => {
        setStatements(res.data.results);
        setNoPages(getNoPages(res.data.count, 50));
      });
    }
  }
  useEffect(() => {
    fetchData();
  }, [open, page]);

  useEffect(() => {
    if (page == 1) {
      fetchData();
    } else {
      setPage(1);
    }
  }, [accountFilter]);
  function handleSubmit() {
    emailSGE(open).then((res) => {
      alert(`Email process started for ${open}`);
    });
  }

  const bb = batches.filter((b) => b.id == open);
  const bbText =
    bb.length > 0 ? `${bb[0].start_date} - ${bb[0].end_date}` : "-";
  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth="lg"
        fullWidth
        sx={{ zIndex: 1 }}
      >
        <DialogTitle sx={{ fontWeight: "bolder", mb: "30px" }}>
          Batch {open} ({bbText})
        </DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mb: 1 }}
            placeholder="Account Filter"
            value={accountFilter}
            onChange={(e) => setAccountFilter(e.target.value)}
          />
          <DataTable
            columnDefs={batchStatsCols}
            rowData={statements}
            rowHeight={53}
            headerHeight={60}
            page={page}
            pageSize={50}
            noPages={noPages}
            setPage={(p) => setPage(p)}
          />
        </DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            m: "20px",
            gap: "10px",
          }}
        >
          <Button
            variant="contained"
            color="success"
            onClick={() => handleSubmit()}
          >
            email all
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
        </Box>
      </Dialog>
    </>
  );
}
