import DataTable from "../../components/DataTable";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  IconButton,
  Input,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { formatAgGridSorting } from "../../distflowAPI/common";
import { useSearchParams } from "react-router-dom";
import CollectionsCols from "./collectionsComponents/tableCols";
import {
  createAccountTasks,
  deleteAccountTask,
  listAccountTasks,
  listBalances,
  patchAccountTask,
} from "../../distflowAPI/invoicesApi";
import Block from "../../components/Block";
import DatePickerCustom2 from "../../components/DatePicker2";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../utils/constants";
import {
  Add,
  AddCircle,
  Edit,
  CheckCircle,
  Delete,
  Link,
  Save,
} from "@mui/icons-material";
import { listManagers } from "../../distflowAPI/managersApi";
import { AgGridReact } from "ag-grid-react";
import useAppSelector from "../../hooks/useAppSelector";
import ConfirmDialog from "../../components/ConfirmDialog";
import { updateAccountApi } from "../../distflowAPI/accountsApi";
import { getNoPages } from "../../utils";
const REDUCER_ACTIONS = {
  SET_ACCOUNT: "SET_ACCOUNT",
  SET_VAL: "SET_VAL",
};
const INIT_DATA = {
  selectedAccount: {
    label: "",
    value: null,
    customerId: null,
    generalNote: "test general note...",
  },
};
function reducer(state, action) {
  switch (action.type) {
    case REDUCER_ACTIONS.SET_VAL: {
      return { ...state, [action.key]: action.value };
    }
  }
}

const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

const Collections = () => {
  const authUser = useAppSelector((state) => state.user.authUser);
  const isInitialized = useAppSelector((state) => state.user.isInitialized);
  const gridRef = useRef();
  const [data, dispatch] = useReducer(reducer, INIT_DATA);
  const [collections, setCollections] = useState([]);
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(1);
  const [noPages, setNoPages] = useState(1);
  const [orderBy, setOrderBy] = useState("date");
  async function fetchData() {
    await listBalances(
      page,
      pageSize,
      {
        ...filters,
        life_time_balance__gte:
          filters.account || filters.internal_id ? -100000000 : 1,
        hasCollector: getHasCollectorFilterVal(),
      },
      orderBy
    ).then((res) => {
      const normalizedData = res.data.result;
      setCollections(normalizedData);
      setFilteredCollections(normalizedData);
      setIsLoading(false);
      setNoPages(getNoPages(res.data.count, pageSize));
      console.log(
        "data loaded, checking against 1; ",
        !data.selectedAccount?.value
      );
      console.log(
        "data loaded, checking against 2; ",
        normalizedData?.length > 0
      );
    });
  }
  function onGridReady() {
    fetchData();
  }
  const [filters, setFilters] = useState({
    account: "",
    hasCollector: "ALL",
    collector: `${authUser?.first_name} ${authUser?.last_name}`,
  });
  function getHasCollectorFilterVal() {
    if (filters.hasCollector == "HAS_COLLECTOR") {
      return "true";
    }
    if (filters.hasCollector == "HAS_NO_COLLECTOR") {
      return "false";
    }
    return null;
  }
  function refreshData() {
    fetchData();
  }
  useEffect(() => {
    if (page == 1) {
      refreshData();
    } else {
      setPage(1);
    }
  }, [filters, pageSize, orderBy]);
  useEffect(() => {
    refreshData();
  }, [page]);
  return (
    <>
      <Stack spacing={1}>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <TextField
            label={"collector"}
            value={filters.collector}
            onChange={(e) =>
              setFilters({ ...filters, collector: e.target.value })
            }
          />
          <TextField
            label={"account name"}
            value={filters.account}
            onChange={(e) =>
              setFilters({ ...filters, account: e.target.value })
            }
          />
          <TextField
            label={"account ID"}
            value={filters.internal_id}
            onChange={(e) =>
              setFilters({ ...filters, internal_id: e.target.value })
            }
          />
          <Select
            value={filters.hasCollector}
            sx={{
              "& .MuiSelect-select": {
                paddingTop: "8.5px",
                paddingBottom: "8.5px",
              },
            }}
            onChange={(e) =>
              setFilters({ ...filters, hasCollector: e.target.value })
            }
          >
            <MenuItem value={"ALL"}>All collector states</MenuItem>
            <MenuItem value={"HAS_COLLECTOR"}>Has a collecor</MenuItem>
            <MenuItem value={"HAS_NO_COLLECTOR"}>
              Does not have a collector
            </MenuItem>
          </Select>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Button disabled={page == 1} onClick={() => setPage(page - 1)}>
              Previous
            </Button>
            <Typography>
              Page {page} of {noPages}
            </Typography>
            <Button
              disabled={page == noPages}
              onClick={() => setPage(page + 1)}
            >
              Next
            </Button>
            <Select
              value={pageSize}
              onChange={(e) => setPageSize(e.target.value)}
            >
              <MenuItem value={10}>10 rows</MenuItem>
              <MenuItem value={50}>50 rows</MenuItem>
              <MenuItem value={100}>100 rows</MenuItem>
            </Select>
          </Stack>
        </Stack>

        <Box className="table-wrapper" overflow={"auto"} maxHeight={"40vh"}>
          <div style={{ width: "100%" }} className="ag-theme-alpine">
            <AgGridReact
              animateRows
              domLayout="autoHeight"
              ref={gridRef}
              onGridReady={onGridReady}
              rowData={filteredCollections}
              columnDefs={CollectionsCols}
              //defaultColDef={defaultColDef}
              suppressSorting={true} //disable default sorting
              rowHeight={33}
              headerHeight={42}
              rowSelection={"single"}
              onSortChanged={(e) => {
                const sortingFields =
                  e.columnApi.columnModel.gridColumns.filter((f) => f.sort);
                console.log("sorting fields aee = ", sortingFields);
                if (
                  sortingFields &&
                  sortingFields?.length > 0 &&
                  sortingFields[0].colId
                ) {
                  setOrderBy(
                    `${sortingFields[0].sort == "desc" ? "-" : ""}` +
                      sortingFields[0].colId
                  );
                } else {
                  setOrderBy("");
                }
              }}
              onRowClicked={(e) => {
                console.log("selected: ", e.data);
                dispatch({
                  type: REDUCER_ACTIONS.SET_VAL,
                  key: "selectedAccount",
                  value: {
                    label: e.data.account_name,
                    value: e.data.account_id,
                    customer_id: e.data.customer_id,
                    generalNote: e.data.account_general_note,
                  },
                });
              }}
              onRowDataUpdated={(params) => {
                const currentRowData = params.api.getRenderedNodes();
                if (
                  !data.selectedAccount?.value &&
                  currentRowData?.length > 0
                ) {
                  dispatch({
                    type: REDUCER_ACTIONS.SET_VAL,
                    key: "selectedAccount",
                    value: {
                      label: currentRowData[0]?.data?.account_name,
                      value: currentRowData[0]?.data?.account_id,
                      customer_id: currentRowData[0]?.data?.customer_id,
                      generalNote:
                        currentRowData[0]?.data?.account_general_note,
                    },
                  });
                }
                console.log("first data rendered...", currentRowData);
              }}
            />
          </div>
        </Box>
        <Box maxHeight={"70vh"} overflow={"auto"}>
          <Block>
            <Tasks data={data} dispatch={dispatch} />
          </Block>
        </Box>
      </Stack>
    </>
  );
};

export default Collections;

const TASK_REDUCER_ACTIONS = {
  SET_TASKS: "SET_TASKS",
  ADD_TASK: "ADD_TASK",
  EDIT_TASK: "EDIT_TASK",
  SET_SAVED: "SET_SAVED",
  DELETE_TASK: "DELETE_TASK",
  SET_VAL: "SET_VAL",
};
const TASK_INIT_DATA = {
  account_general_note: "",
  tasks: [],
};
function tasks_reducer(state, action) {
  switch (action.type) {
    case TASK_REDUCER_ACTIONS.SET_TASKS: {
      const newVal = { ...state };
      newVal.tasks = action.value;
      newVal.account_general_note = "";
      if (action.value && action.value.length > 0) {
        newVal.account_general_note = action.value[0].account_general_note;
      }
      return newVal;
    }
    case TASK_REDUCER_ACTIONS.SET_VAL: {
      const newVal = { ...state };
      newVal[action.name] = action.value;
      return newVal;
    }
    case TASK_REDUCER_ACTIONS.EDIT_TASK: {
      const newTasks = { ...state };
      newTasks.tasks[action.index][action.name] = action.value;
      newTasks.tasks[action.index]["changed"] = true;
      newTasks.tasks[action.index]["saved"] = false;
      return newTasks;
    }
    case TASK_REDUCER_ACTIONS.SET_SAVED: {
      const newTasks = { ...state };
      newTasks.tasks[action.index]["changed"] = false;
      newTasks.tasks[action.index]["saved"] = true;
      return newTasks;
    }
    case TASK_REDUCER_ACTIONS.DELETE_TASK: {
      const newTasks = { ...state };
      newTasks.tasks.splice(action.index, 1);
      return newTasks;
    }
  }
}
function Tasks({ data, dispatch }) {
  const [tasks_data, tasks_dispatch] = useReducer(
    tasks_reducer,
    TASK_INIT_DATA
  );
  const [loadingTasks, setLoadingTasks] = useState(false);
  useEffect(() => {
    fetchData();
  }, [data]);
  const [notesOpen, setNotesOpen] = useState(true);
  function toggleNotesOpen(e) {
    return;
    setNotesOpen((prev) => !prev);
  }
  function fetchData(same) {
    if (!same) {
      setLoadingTasks(true);
      tasks_dispatch({
        type: TASK_REDUCER_ACTIONS.SET_TASKS,
        value: [],
      });
    }
    if (data.selectedAccount.value) {
      listAccountTasks(data.selectedAccount.value)
        .then((res) =>
          tasks_dispatch({
            type: TASK_REDUCER_ACTIONS.SET_TASKS,
            value: res.data.results,
          })
        )
        .catch(() => {})
        .finally(() => {
          setLoadingTasks(false);
        });
    }
  }
  function handleCreateTask() {
    createAccountTasks({
      date: dayjs().format(DATE_FORMAT),
      content: "",
      done: false,
      account: data.selectedAccount.value,
    }).then(() => fetchData(true));
  }
  if (!data.selectedAccount.value) {
    return (
      <Stack spacing={1}>
        <Typography>No Account selected</Typography>
      </Stack>
    );
  }
  return (
    <Stack spacing={1}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          alignContent={"center"}
          spacing={1}
        >
          <Typography>Account: {data.selectedAccount.label}</Typography>
          <IconButton color="success" onClick={() => handleCreateTask()}>
            <AddCircle />
          </IconButton>
        </Stack>
        <Stack direction="row">
          <Button
            onClick={() => {
              console.log("selected account is: ", data.selectedAccount);
              window.open(
                `/customers/dashboard/${data.selectedAccount.customer_id}/invoices?ordering=issued_date&status=UNPAID%2CCREDIT%2CPARTIALLY_PAID`,
                "_blank",
                "noreferrer"
              );
            }}
          >
            Invoices
          </Button>
          <Button
            onClick={() => {
              console.log("selected account is: ", data.selectedAccount);
              window.open(
                `/customers/dashboard/${data.selectedAccount.customer_id}/payments?ordering=-payment_date`,
                "_blank",
                "noreferrer"
              );
            }}
          >
            Payments
          </Button>
        </Stack>
      </Stack>
      <Box hidden={!notesOpen}>
        <TextField
          sx={{ width: "100%" }}
          id="notes"
          label={"General Notes:" + data.selectedAccount.label}
          multiline
          value={tasks_data.account_general_note}
          onChange={(e) => {
            tasks_dispatch({
              type: TASK_REDUCER_ACTIONS.SET_VAL,
              name: "account_general_note",
              value: e.target.value,
            });
            updateAccountApi(data.selectedAccount?.value, {
              account_general_note: e.target.value,
            });
            //updateAccountGeneralNote(data.selectedAccount.id, e.target.value);
          }}
        />
      </Box>
      <Box maxHeight={"30vh"} overflow={"auto"}>
        {loadingTasks && <Typography>Loading...</Typography>}
        {tasks_data.tasks.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={"15%"} padding="2">
                  <Typography>Next Call</Typography>
                </TableCell>
                <TableCell width={"50%"} padding="2">
                  <Typography>Memo</Typography>
                </TableCell>
                <TableCell>
                  <Typography width={"5%"} padding="2">
                    Done?
                  </Typography>
                </TableCell>
                <TableCell width={"10%"} padding="2">
                  <Typography>Manager</Typography>
                </TableCell>
                <TableCell width={"15%"} padding="2">
                  <Typography>Created At</Typography>
                </TableCell>
                <TableCell width={"5%"} padding="2">
                  <Typography>-</Typography>
                </TableCell>
              </TableRow>
              {tasks_data.tasks.map((task, index) => (
                <Task
                  data={tasks_data.tasks}
                  dispatch={tasks_dispatch}
                  index={index}
                />
              ))}
            </TableHead>
            <TableBody></TableBody>
          </Table>
        ) : (
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Typography>No tasks yet</Typography>
            <Button onClick={() => handleCreateTask()}>
              Click to create one
            </Button>
          </Stack>
        )}
      </Box>
    </Stack>
  );
}

function Task({ data, dispatch, index }) {
  const [confirmOpen, setConfirmOpen] = useState(false);
  function updateVal(name, value) {
    dispatch({
      type: TASK_REDUCER_ACTIONS.EDIT_TASK,
      index: index,
      value,
      name,
    });
    let newVal = value;
    if (name == "date") {
      newVal = newVal.format(DATE_FORMAT);
    }
    patchAccountTask(data[index].id, { [name]: newVal }).then((res) => {
      console.log("save res: ", res.data);
      dispatch({
        type: TASK_REDUCER_ACTIONS.SET_SAVED,
        index: index,
      });
    });
  }

  function handleSave() {}
  return (
    <>
      <ConfirmDialog
        title="Confirm Delete"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={() => {
          deleteAccountTask(data[index].id);
          dispatch({
            type: TASK_REDUCER_ACTIONS.DELETE_TASK,
            index: index,
          });
        }}
      >
        {"Are you sure you want to remove this ?"}
      </ConfirmDialog>
      <TableRow>
        <TableCell sx={{ padding: 0.1 }}>
          <DatePickerCustom2
            controlledValue={dayjs(data[index].date)}
            setControlledValue={(v) => updateVal("date", v)}
            fontSize="12px"
          />
        </TableCell>
        <TableCell sx={{ padding: 0.1, paddingLeft: 2, paddingRight: 2 }}>
          <Input
            value={data[index]?.content}
            onChange={(e) => updateVal("content", e.target.value)}
            fullWidth
            fontSize="12px"
          />
        </TableCell>
        <TableCell sx={{ padding: 0.1 }}>
          <Checkbox
            checked={data[index].done}
            onChange={(e) => updateVal("done", e.target.checked)}
          />
        </TableCell>
        <TableCell sx={{ padding: 0.1 }}>
          <Typography>{data[index].user.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography>
            {data[index].created_at
              ? dayjs(data[index].created_at).format("MMM, DD, YY HH:MM")
              : "created_at"}
          </Typography>
        </TableCell>
        <TableCell sx={{ padding: 0.1 }}>
          <Stack
            direction={"row"}
            spacing={1}
            justifyItems={"center"}
            justifyContent={"center"}
          >
            <IconButton
              onClick={() => handleSave()}
              disabled={!(data[index].changed && !data[index].saved)}
              color={"success"}
            >
              <Save />
            </IconButton>
            <IconButton
              color="error"
              onClick={() => {
                setConfirmOpen(true);
              }}
            >
              <Delete />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
}
