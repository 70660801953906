import {
  AddCircleOutline,
  DeleteOutline,
  Visibility,
} from "@mui/icons-material";
import { REDUCER_ACTIONS } from "../config";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Separator from "../../../components/Separator";
import { listVariants } from "../../../distflowAPI/ordersApi";
import Product from "../../manageAutoOrders/Product";

function InvoiceReturns({ data, dispatch }) {
  return (
    <>
      <Stack spacing={1} direction={"row"}>
        <Typography variant="h6">Returns</Typography>
        <IconButton
          onClick={() =>
            dispatch({
              type: REDUCER_ACTIONS.SET_CONFIG_VAL,
              name: "addReturn",
              value: true,
            })
          }
          color="error"
        >
          <AddCircleOutline />
        </IconButton>
      </Stack>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography fontSize="12px" fontWeight={600} mb="0px" padding="0">
                Description
              </Typography>
            </TableCell>
            <TableCell>
              <Typography fontSize="12px" fontWeight={600} mb="0px">
                SKU
              </Typography>
            </TableCell>
            <TableCell>
              <Typography fontSize="12px" fontWeight={600} mb="0px">
                Unit
              </Typography>
            </TableCell>
            <TableCell>
              <Typography fontSize="12px" fontWeight={600} mb="0px">
                U.Price
              </Typography>
            </TableCell>
            <TableCell>
              <Typography fontSize="12px" fontWeight={600} mb="0px">
                Returned QTY
              </Typography>
            </TableCell>
            <TableCell>
              <Typography fontSize="12px" fontWeight={600} mb="0px">
                Total
              </Typography>
            </TableCell>
            <TableCell>
              <Typography fontSize="12px" fontWeight={600} mb="0px">
                Return Type
              </Typography>
            </TableCell>
            <TableCell>
              <Typography fontSize="12px" fontWeight={600} mb="0px">
                Return Reason
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                fontSize="12px"
                fontWeight={600}
                mb="0px"
              ></Typography>
            </TableCell>{" "}
          </TableRow>
          {data.returns.map((item, index) => (
            <TableRow>
              <TableCell>
                <Typography fontSize="12px" fontWeight={400}>
                  {item.display_name || item.name} ({item.id ? item.id : "New"})
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize="12px" fontWeight={400}>
                  {item.sku}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize="12px" fontWeight={400}>
                  {item.unit}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize="12px" fontWeight={400}>
                  <Stack direction={"row"}>
                    <Input
                      value={item.unit_price}
                      onChange={(e) => {
                        dispatch({
                          type: REDUCER_ACTIONS.UPDATE_RETURN,
                          name: "unit_price",
                          value: e.target.value,
                          index,
                        });
                      }}
                      sx={{ maxWidth: 100 }}
                    />
                  </Stack>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize="12px" fontWeight={400}>
                  <Stack direction={"row"}>
                    <Input
                      value={item.quantity}
                      onChange={(e) => {
                        dispatch({
                          type: REDUCER_ACTIONS.UPDATE_RETURN,
                          name: "quantity",
                          value: e.target.value,
                          index,
                        });
                      }}
                      sx={{ maxWidth: 100 }}
                    />
                  </Stack>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize="12px" fontWeight={400}>
                  ${(item.quantity * item.unit_price).toFixed(2)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize="12px" fontWeight={400}>
                  {item.return_type}
                </Typography>
              </TableCell>
              <TableCell padding="0">
                <Stack direction={"row"} alignItems={"center"}>
                  <Input
                    value={item.return_reason || ""}
                    onChange={(e) => {
                      dispatch({
                        type: REDUCER_ACTIONS.UPDATE_RETURN,
                        name: "return_reason",
                        value: e.target.value,
                        index,
                      });
                    }}
                    fullWidth
                  />
                </Stack>
              </TableCell>
              <TableCell>
                <IconButton
                  color="error"
                  onClick={() => {
                    dispatch({
                      type: REDUCER_ACTIONS.DELETE_RETURN,
                      index,
                    });
                  }}
                >
                  <DeleteOutline />
                </IconButton>
                <IconButton
                  onClick={() => {
                    window.open(
                      `/products/${item?.product_id}/variants/${item?.variant}`,
                      "_blank"
                    );
                    console.log("view item: ", item);
                  }}
                >
                  <Visibility />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <AddReturnModal data={data} dispatch={dispatch} />
    </>
  );
}

export default InvoiceReturns;

function AddReturnModal({ data, dispatch }) {
  const [reason, setReason] = useState("");
  const [qty, setQty] = useState(1);
  const [returnType, setReturnType] = useState("OFFICE_MISTAKE");
  const [rt, setRt] = useState("EXISTING");
  function changeQty(e) {
    const newQty = e.target.value;

    if (newQty < 0) {
      setQty(0);
      return;
    }
    setQty(newQty);
  }

  function addReturn() {
    if (!reason) {
      alert("please specify a return reason");
      return;
    }
    dispatch({
      type: REDUCER_ACTIONS.ADD_RETURN,
      payload: {
        sku: data.config.item_to_return.sku,
        unit_price: data.config.item_to_return.unit_price,
        display_name:
          data.config.item_to_return.display_name ||
          data.config.item_to_return.name,
        unit:
          data.config.item_to_return.unit || data.config.item_to_return.sold_by,
        quantity: parseFloat(qty),
        return_reason: reason,
        return_type: returnType,
      },
    });
    closeAddReturn();
  }
  function closeAddReturn() {
    dispatch({
      type: REDUCER_ACTIONS.SET_CONFIG_VAL,
      name: "addReturn",
      value: false,
    });
    dispatch({
      type: REDUCER_ACTIONS.SET_CONFIG_VAL,
      name: "item_to_return",
      value: false,
    });

    setQty(1);
    setReason("");
  }

  function setSelectedItem(item) {
    dispatch({
      type: REDUCER_ACTIONS.SET_CONFIG_VAL,
      name: "item_to_return",
      value: item,
    });
  }
  return (
    <Dialog open={data.config.addReturn} fullWidth>
      <DialogTitle>Create a return</DialogTitle>
      <DialogContent>
        <Separator />
        {!data.config.item_to_return && (
          <Stack spacing={2}>
            <Stack direction={"row"} justifyContent={"center"} spacing={1}>
              <Button variant="outlined" onClick={() => setRt("EXISTING")}>
                Return existing
              </Button>
              <Button variant="outlined" onClick={() => setRt("NEW")}>
                Return non existing
              </Button>
            </Stack>
            {rt == "EXISTING" ? (
              <SelectReturnExisting data={data} dispatch={dispatch} />
            ) : (
              <SelectReturnNew data={data} dispatch={dispatch} />
            )}
          </Stack>
        )}

        {data.config.item_to_return && (
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography>
              Returning {data.config.item_to_return.display_name},{" "}
              {data.config.item_to_return.sku}
            </Typography>
            <Button
              onClick={() =>
                dispatch({
                  type: REDUCER_ACTIONS.SET_CONFIG_VAL,
                  name: "item_to_return",
                  value: false,
                })
              }
            >
              Change
            </Button>
          </Stack>
        )}
        <Stack>
          <Separator />
          <Typography>Return QTY</Typography>
          <Stack direction={"row"}>
            <TextField fullWidth onChange={(e) => changeQty(e)} value={qty} />
            <Stack marginLeft={1}>
              <Button
                variant="outlined"
                sx={{
                  maxHeight: "19px",
                  maxWidth: "15px",
                  minWidth: "5px",
                  marginBottom: "2px",
                  fontSize: "20px",
                  fontWeight: 400,
                }}
                onClick={(e) => changeQty({ target: { value: qty + 1 } })}
              >
                +
              </Button>
              <Button
                variant="outlined"
                sx={{
                  maxHeight: "19px",
                  maxWidth: "15px",
                  minWidth: "5px",
                  fontSize: "25px",
                  fontWeight: 400,
                }}
                onClick={(e) => changeQty({ target: { value: qty - 1 } })}
                disabled={qty === 1}
              >
                -
              </Button>
            </Stack>
          </Stack>

          <Typography>Return Type</Typography>
          <Select
            label={"Return Type"}
            fullWidth
            MenuProps={{
              style: {
                //height: 40,
                zIndex: 1500,
              },
            }}
            //sx={{ height: 40 }}
            value={returnType}
            onChange={(e) => setReturnType(e.target.value)}
          >
            <MenuItem value={"OFFICE_MISTAKE"}>Office issue</MenuItem>
            <MenuItem value={"DAMAGED"}>Bakery issue</MenuItem>
          </Select>
          <Typography>Return Reason</Typography>
          <TextField
            fullWidth
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            rows={3}
            multiline
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={1}>
          <Button
            onClick={() => closeAddReturn()}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            disabled={!data.config.item_to_return}
            onClick={() => addReturn()}
          >
            Confirm
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

function SelectReturnExisting({ data, dispatch }) {
  function setSelectedItem(item) {
    dispatch({
      type: REDUCER_ACTIONS.SET_CONFIG_VAL,
      name: "item_to_return",
      value: item,
    });
  }
  return (
    <Box overflow={"auto"} maxHeight={200}>
      {data.items.length > 0 && !data.config.item_to_return && (
        <Stack>
          {data.items
            .filter(
              (it) => !data.returns.map((ret) => ret.sku).includes(it.sku)
            )
            .map((item) => (
              <Stack direction="row" alignItems={"center"} spacing={1}>
                <Typography>
                  {item.display_name} | {item.sku}
                </Typography>
                <Button onClick={() => setSelectedItem(item)}>Select</Button>
              </Stack>
            ))}
        </Stack>
      )}
    </Box>
  );
}

function SelectReturnNew({ data, dispatch }) {
  const [errors, setErrors] = useState({});
  const [variants, setVariants] = useState([]);
  const [totalPages, setTotalPages] = useState(2);
  const [productsPage, setProductsPage] = useState(1);
  const [filterVariant, setFilterVariant] = useState("");
  function updateVariantsList() {
    if (errors.length) setErrors([]);

    if (data.order.account && data.order.account.id) {
      listVariants(
        10,
        productsPage,
        [
          { value: data.order.account.id, column: "accountId" },
          { value: filterVariant, column: "search" },
        ],
        null
      ).then((res) => {
        const newVariants = res.results.map((v) => {
          const item = {
            ...v,
            label: v.name,
            ordered: false,
            ordered_pc: false,
            vendor_details: {
              vendorName: v.vendor,
            },
          };
          return item;
        });
        setVariants(newVariants);
        setTotalPages(Math.ceil(res.count / 10));
      });
    } else {
      setVariants([]);
    }
  }
  function setItemToAdd(d) {
    dispatch({
      type: REDUCER_ACTIONS.SET_CONFIG_VAL,
      name: "item_to_return",
      value: {
        ...d.itemToAdd.item,
        display_name: d.itemToAdd.item.name,
        unit_price: parseFloat(d.itemToAdd.item?.price?.price || 0),
      },
    });
  }

  useEffect(() => {
    if (data.order.account.id && data.order.fulfillment_date) {
      updateVariantsList();
    }
  }, [filterVariant]);
  function canAdd(item) {
    const currentItems = data.returns.map((item) => item.sku);
    if (currentItems.includes(item.sku)) {
      if (!item.customizable) {
        return false;
      }
    }
    return true;
  }
  return (
    <Stack padding={2} spacing={1}>
      <TextField
        label="Search"
        value={filterVariant}
        onChange={(e) => setFilterVariant(e.target.value)}
      />
      <Box overflow={"auto"} maxHeight={250}>
        <Stack spacing={1}>
          {variants
            .filter((v) => canAdd(v))
            .map((item, index) => (
              <Product
                parent
                item={item}
                account={data.order.account}
                // add item modal
                customAdd={(data) => setItemToAdd({ itemToAdd: data, index })}
                showView
              />
            ))}
        </Stack>
      </Box>
    </Stack>
  );
}
