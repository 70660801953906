import React, { useState } from "react";
import { IconButton, ImageListItem, ImageListItemBar } from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ConfirmDialog from "../../components/ConfirmDialog";
import { deleteProductImage, deleteProductVariantImage } from "../../distflowAPI/products";

const ProductImageListItem = ({productId, variantId, item, setImages, fetchItem, type}) => {
  const [confirmOpen, setConfirmOpen] = useState(false)

  const handleDelete = async () => {
    if (item.id) {      
      if (type === "product") {
        await deleteProductImage(productId, item.id)
      } else {
        await deleteProductVariantImage(productId, variantId, item.id)
      }
      await fetchItem()
   } else {      
      setImages((prev) => prev.filter(image =>  image.name !== item.name))
    }
  }

  return (
    <>
      <ConfirmDialog
        title="Delete image?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleDelete}
      >
        Are you sure you want to delete this image? This action can't be undone!
      </ConfirmDialog>
      <ImageListItem style={{border: "1px solid #DBDFEA", borderRadius: "5px"}}>
        <img
          src={`${item.image || item.preview}`}
          loading="lazy"
          style={{overflow: "hidden", objectPosition: "center"}}
        />
        <ImageListItemBar
          actionIcon={
            <IconButton
              sx={{ color: "rgba(255, 255, 255, 0.54)" }}
              onClick={() => { setConfirmOpen(true) }}
            >
              <DeleteForeverOutlinedIcon sx={{ color: "white" }} />
            </IconButton>
          }
        />
      </ImageListItem>
    </>
  );
};

export default ProductImageListItem;
